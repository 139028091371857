$(document).ready(function () {

    $('#autoPaySuccessModal').on('hidden.bs.modal', function () {
        location.reload();
    });
    $(".enroll-checkbox").on("click", function () {
        if ($(".enroll-checkbox").attr('class').indexOf('checkbox-selected') > -1) {
            $("#enrollConfirmTerms").prop('checked', false);
            $(".enroll-checkbox").removeClass('checkbox-selected');
            $("#enrollConfirmationbtn, #enrollConfirmationmobbtn").attr("disabled", "disabled");
        } else {
            $("#enrollConfirmTerms").prop('checked', true);
            $(".enroll-checkbox").addClass('checkbox-selected');
            $("#enrollConfirmationbtn, #enrollConfirmationmobbtn").removeAttr('disabled');
        }
    });
    $('#changePaymentDetail').on('hidden.bs.collapse', function () {
        $('.changePayment1').removeClass('border-blue');
        $('#makePaymentChange1').html(ACC.paymentChangeLbl + ' <i class="fas fa-caret-down"></i>');
    });
    $('#changePaymentDetail').on('shown.bs.collapse', function () {
        $('.changePayment1').addClass('border-blue');
        $('#makePaymentChange1').html(ACC.paymentUpdateLbl +' <i class="fas fa-caret-up"></i>');
    });
    
     $('#changePaymentDetailAuto').on('hidden.bs.collapse', function () {
        $('.changePaymentAuto').removeClass('border-blue');
        $('#makePaymentChangeAuto').html(ACC.paymentChangeLbl + ' <i class="fas fa-caret-down"></i>');
    });
    $('#changePaymentDetailAuto').on('shown.bs.collapse', function () {
        $('.changePaymentAuto').addClass('border-blue');
        $('#makePaymentChangeAuto').html(ACC.paymentUpdateLbl +' <i class="fas fa-caret-up"></i>');
    });
    
    var firstName = $('.firstNameHidden').text();

    var email = $('.emailHidden').text();

    function checkLoginSessionTimeout(response) {
        var data = response ? JSON.parse(response) : null;
        return data && data.sessionTimeout ? true : false;
    }

    $(".deskTopView .save_button").on('click', function () {
        $('.modalViewEnroll').empty();
        $(".footerSectionAgreement").empty();
        var k = 0;
        arrayEnroll = [];
        $('.selectType').each(function (i, obj) {
            if ($(this).val() != 0) {
                var productName = $(this).closest('.productRows').children(".column_1").text();

                var productAgreementNumber = $(this).closest('.productRows').children(".column_2").text();

                var productAgreementID = $(this).closest('.productRows').children(".agreementIDHidden").text();

                var productCustomerID = $(this).closest('.productRows').children(".customerIDHidden").text();

                var productFrequency = $(this).closest('.productRows').children(".column_4").text();

                var productPrice = $(this).closest('.productRows').children(".column_5").text().replace('$', '').trim();

                var productSelectedMethod = $(this).find('option:selected').text();

                var agreementDescription = $(this).closest('.productRows').children(".agreementDescriptionHidden").text();

                var searchType = $(this).closest('.productRows').children(".searchTypeIDHidden").text();

                var currentPaymentDateArray = $(this).closest('.productRows').children(".column_3").text().split("/");

                var currentPaymentDate = currentPaymentDateArray[2] + currentPaymentDateArray[0] + currentPaymentDateArray[1];

                $('.modalViewEnroll').append('<div class="productRows productRowID_' + k + ' row"><span class="column_1 column_common">'+ACC.benifitPlus+'</span><span class="column_2 column_common">02221KI234</span><span class="column_6 column_common"><span class="left_selected_method ">asdasd</span></span></div>');

                column1 = '.modalViewEnroll .productRowID_' + k + ' .column_1';
                column2 = '.modalViewEnroll .productRowID_' + k + ' .column_2';
                column3 = '.modalViewEnroll .productRowID_' + k + ' .column_6 .left_selected_method';

                $(column1).empty().text(productName);
                $(column2).empty().text(productAgreementNumber);
                $(column3).empty().text(productSelectedMethod);

                $('.footerSectionAgreement').append('<div class="col-xs-12 product_' + k + '_footer modal_footer_value"></div>');
                footerRow0 = '.product_' + k + '_footer.modal_footer_value';

                $(footerRow0).empty().text(ACC.authorizingRentacenter + productPrice + ACC.forTheAgreement +" (" + productAgreementNumber + ') '  + ACC.describedInAgreement);

                object = {
                    agreementID: productAgreementID,
                    enrollInAutoPay: "1",
                    schedule: productFrequency,
                    firstName: firstName,
                    email: email,
                    customerID: productCustomerID,
                    paymentAmount: productPrice,
                    agreementNumber: productAgreementNumber,
                    agreementDescription: agreementDescription,
                    searchType: searchType,
                    paymentMethodID: $(this).val(),
                    paymentDate: currentPaymentDate,
                    'paymentMethodNotificationData': {
                        'paymentMethodID': $(this).val(),
                        'lastDigits': $('option:selected', this).attr('data-lastDigits'),
                        'type': $('option:selected', this).attr('data-pType')

                    }

                }
                arrayEnroll.push(object);
                console.log(arrayEnroll);


                k++;
            }
            else {
                console.log("1", true);
            }
        });

        sessionTimeoutCall()
            .done(function (data, textStatus) {
                if (!checkLoginSessionTimeout(data)) {
                    $('#manageEnroll').modal({
                        backdrop: 'static',
                        keyboard: false
                    });
                    $('#manageEnroll').modal('show');
                }
                else {
                    window.location.href = "/";
                }
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                $('#serviceDown').modal('show');
            })
            .always(function () {
            });
    });



    $(".mobileView .save_button").on('click', function () {
        $('.modalViewEnroll').empty();
        $(".footerSectionAgreement").empty();
        var k = 0;
        arrayEnroll = [];
        $('.selectTypeMobile').each(function (i, obj) {
            if ($(this).val() != 0) {
                var productName = $(this).closest('.agreement').children(".rowMobile1").children(".column_1").text();

                var productAgreementID = $(this).closest('.agreement').children(".rowMobile2").children(".column_1").children(".row-data").children(".payment-details-info").children(".mobileAgreementID").text();

                var productCustomerID = $(this).closest('.agreement').children(".rowMobile2").children(".column_1").children(".row-data").children(".payment-details-info").children(".mobileCustomerIDHidden").text();

                var productAgreementNumber = $(this).closest('.agreement').children(".rowMobile2").children(".column_1").children(".row-data").children(".payment-details-info").children(".agreement_row").children(".detaillinkRight").text();

                var productFrequency = $(this).closest('.agreement').children(".rowMobile2").children(".column_1").children(".row-data").children(".payment-details-info").children(".frequency_row").children(".detaillinkRight").text();

                var productPrice = $(this).closest('.agreement').children(".rowMobile2").children(".column_1").children(".row-data").children(".payment-details-info").children(".amount_row").children(".detaillinkRight").text().replace('$', '').trim();

                var productSelectedMethod = $(this).find('option:selected').text();

                var searchType = $(this).closest('.agreement').children(".rowMobile2").children(".column_1").children(".row-data").children(".payment-details-info").children(".mobileSearchTypeIDHidden").text();

                var currentPaymentDateArray = $(this).closest('.agreement').children(".rowMobile2").children(".column_1").children(".row-data").children(".payment-details-info").children(".date_row").children(".detaillinkRight").text().split("/");

                var currentPaymentDate = currentPaymentDateArray[2] + currentPaymentDateArray[0] + currentPaymentDateArray[1];

                $('.modalViewEnroll').append('<div class="productRows productRowID_' + k + ' row"><span class="column_1 column_common">'+ ACC.benifitPlus +'</span><span class="column_2 column_common">02221KI234</span><span class="column_6 column_common"><span class="left_selected_method ">asdasd</span></span></div>');

                column1 = '.modalViewEnroll .productRowID_' + k + ' .column_1';
                column2 = '.modalViewEnroll .productRowID_' + k + ' .column_2';
                column3 = '.modalViewEnroll .productRowID_' + k + ' .column_6 .left_selected_method';

                $(column1).empty().text(productName);
                $(column2).empty().text(productAgreementNumber);
                $(column3).empty().text(productSelectedMethod);
                $('.footerSectionAgreement').append('<div class="col-xs-12 product_' + k + '_footer modal_footer_value"></div>');
                footerRow0 = '.product_' + k + '_footer.modal_footer_value';

                $(footerRow0).empty().text(ACC.authorizingAutoPayAmtOf + productPrice + ACC.forTheAgreement +" (" + productAgreementNumber + ') ' +ACC.describedInAgreement);

                object = {
                    agreementID: productAgreementID,
                    enrollInAutoPay: "1",
                    schedule: productFrequency,
                    customerID: productCustomerID,
                    firstName: firstName,
                    email: email,
                    paymentAmount: productPrice,
                    paymentMethodID: $(this).val(),
                    agreementNumber: productAgreementNumber,
                    searchType: searchType,
                    paymentDate: currentPaymentDate,
                    'paymentMethodNotificationData': {
                        'paymentMethodID': $(this).val(),
                        'lastDigits': $('option:selected', this).attr('data-lastDigits'),
                        'type': $('option:selected', this).attr('data-pType')
                    }
                }
                arrayEnroll.push(object);
                console.log(arrayEnroll);


                k++;
            }
            else {
                console.log("1", true);
            }
        });

        sessionTimeoutCall()
            .done(function (data, textStatus) {
                if (!checkLoginSessionTimeout(data)) {
                    $('#manageEnroll').modal({
                        backdrop: 'static',
                        keyboard: false
                    });
                    $('#manageEnroll').modal('show');
                }
                else {
                    window.location.href = "/";
                }
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                $('#serviceDown').modal('show');
            })
            .always(function () {
            });
    });



    /*$(".save_button").on('click',function(){
       $('#enrollConfirmation').modal({					
             backdrop: 'dynamic',
             keyboard: false
        });
        $('#enrollConfirmation').modal('show'); 
    });*/

    $('.deskTopView .right_unenroll_option').on('click', function () {

        var productAgreementNumber = $(this).closest('.productRows').children(".column_2").text();

        var productAgreementID = $(this).closest('.productRows').children(".agreementIDHidden").text();

        var productCustomerID = $(this).closest('.productRows').children(".customerIDHidden").text();

        var productFrequency = $(this).closest('.productRows').children(".column_4").text();

        var productPrice = $(this).closest('.productRows').children(".column_5").text().replace('$', '').trim();

        var productSelectedMethod = $(this).closest('.productRows').children(".column_6").children(".autoPayHidden").text();

        var agreementDescription = $(this).closest('.productRows').children(".agreementDescriptionHidden").text();

        var searchType = $(this).closest('.productRows').children(".searchTypeIDHidden").text();

        var currentPaymentDateArray = $(this).closest('.productRows').children(".column_3").text().split("/");

        var currentPaymentDate = currentPaymentDateArray[2] + currentPaymentDateArray[0] + currentPaymentDateArray[1];

        var paymentMethodtype = $(this).closest('.productRows').children(".column_6").children(".autoPayCardType").text();

        var paymentMethodLastDigits = $(this).closest('.productRows').children(".column_6").children(".autoPayLastDigits").text();

		//Updating agreement id and payment id for update autopay
		$('#enrollAutopayUpdateForm #selectedAgreementIds').val(productAgreementNumber);
		$('#enrollAutopayUpdateForm #selectedPaymentMethodId').val(productSelectedMethod);

        unenrollObj = {
            agreementID: productAgreementID,
            enrollInAutoPay: "0",
            schedule: productFrequency,
            customerID: productCustomerID,
            firstName: $('#customerFirstName').text(),
            email: $('#customerEmail').text(),
            paymentAmount: productPrice,
            agreementDescription: agreementDescription,
            paymentMethodID: productSelectedMethod,
            searchType: searchType,
            agreementNumber: productAgreementNumber,
            paymentDate: currentPaymentDate,
            'paymentMethodNotificationData': {
                'paymentMethodID': productSelectedMethod,
                'lastDigits': paymentMethodLastDigits,
                'type': paymentMethodtype
            }
        };

        sessionTimeoutCall()
            .done(function (data, textStatus) {
                if (!checkLoginSessionTimeout(data)) {
                    $('#manageUnEnroll').modal({
                        backdrop: 'dynamic',
                        keyboard: false
                    });
                    $('#manageUnEnroll').modal('show');
                }
                else {
                    window.location.href = "/";
                }
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                $('#serviceDown').modal('show');
            })
            .always(function () {
            });

    });
    $('.mobileView .right_unenroll_option').on('click', function () {


        var productAgreementID = $(this).closest('.agreement').children(".rowMobile2").children(".column_1").children(".row-data").children(".payment-details-info").children(".mobileAgreementID").text();

        var productCustomerID = $(this).closest('.agreement').children(".rowMobile2").children(".column_1").children(".row-data").children(".payment-details-info").children(".mobileCustomerIDHidden").text();

        var productAgreementNumber = $(this).closest('.agreement').children(".rowMobile2").children(".column_1").children(".row-data").children(".payment-details-info").children(".agreement_row").children(".detaillinkRight").text();

        var productFrequency = $(this).closest('.agreement').children(".rowMobile2").children(".column_1").children(".row-data").children(".payment-details-info").children(".frequency_row").children(".detaillinkRight").text();

        var productPrice = $(this).closest('.agreement').children(".rowMobile2").children(".column_1").children(".row-data").children(".payment-details-info").children(".amount_row").children(".detaillinkRight").text().replace('$', '').trim();

        var productSelectedMethod = $(this).closest('.agreement').children(".rowMobile3").children(".column_1").children(".mobileAutoPayHidden").text();

        var agreementDescription = $(this).closest('.agreement').children(".rowMobile2").children(".column_1").children(".row-data").children(".payment-details-info").children(".mobileAgreementDescriptionHidden").text();

        var searchType = $(this).closest('.agreement').children(".rowMobile2").children(".column_1").children(".row-data").children(".payment-details-info").children(".mobileSearchTypeIDHidden").text();

        var currentPaymentDateArray = $(this).closest('.agreement').children(".rowMobile2").children(".column_1").children(".row-data").children(".payment-details-info").children(".date_row").children(".detaillinkRight").text().split("/");

        var currentPaymentDate = currentPaymentDateArray[2] + currentPaymentDateArray[0] + currentPaymentDateArray[1];

        var paymentMethodtype = $(this).closest('.agreement').children(".rowMobile3").children(".column_1").children(".autoPayMobileCardType").text();

        var paymentMethodLastDigits = $(this).closest('.agreement').children(".rowMobile3").children(".column_1").children(".autoPayMobileLastDigits").text();

        unenrollObj = {
            agreementID: productAgreementID,
            enrollInAutoPay: "0",
            schedule: productFrequency,
            customerID: productCustomerID,
            firstName: firstName,
            email: email,
            paymentAmount: productPrice,
            agreementDescription: agreementDescription,
            paymentMethodID: productSelectedMethod,
            searchType: searchType,
            agreementNumber: productAgreementNumber,
            paymentDate: currentPaymentDate,
            'paymentMethodNotificationData': {
                'paymentMethodID': productSelectedMethod,
                'lastDigits': paymentMethodLastDigits,
                'type': paymentMethodtype
            }
        };

        sessionTimeoutCall()
            .done(function (data, textStatus) {
                if (!checkLoginSessionTimeout(data)) {
                    $('#manageUnEnroll').modal({
                        backdrop: 'dynamic',
                        keyboard: false
                    });
                    $('#manageUnEnroll').modal('show');
                }
                else {
                    window.location.href = "/";
                }
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                $('#serviceDown').modal('show');
            })
            .always(function () {
            });
    });
    $('.unEnroll_Button').on('click', function () {
        var buttonElem = $(this);
        buttonElem.attr("disabled", true);
        sessionTimeoutCall()
            .done(function (data, textStatus) {
                if (!checkLoginSessionTimeout(data)) {
                	handleSpinner();
                    RAC.loadingSpinnerShow();
                    $.ajax({
                        url: "/myaccount/unenrollAgreements",
                        type: "post",
                        data: JSON.stringify(unenrollObj),
                        contentType: 'application/json',
                        success: function (data) {
                            if (data) {
                                if (data.updateSuccessForAtleastOne == "true" || data.updateSuccessForAtleastOne == true) {
                                    $('#manageUnEnroll').modal('hide');
                                    $('#agreementUnEnroll').modal('hide');
                                    location.reload();
                                }
                                else {
                                    $('#manageUnEnroll').modal('hide');
                                    $('#agreementUnEnroll').modal('hide');
                                    RAC.loadingSpinnerHide();
                                    $('#serviceDown').modal({
                                        backdrop: 'dynamic',
                                        keyboard: false
                                    });
                                    $('#serviceDown').modal('show');
                                }
                            }
                            else {
                                $('#manageUnEnroll').modal('hide');
                                $('#agreementUnEnroll').modal('hide');
                                RAC.loadingSpinnerHide();
                                $('#serviceDown').modal({
                                    backdrop: 'dynamic',
                                    keyboard: false
                                });
                                $('#serviceDown').modal('show');
                            }

                        },
                        error: function (error) {
                            console.log(error);
                            RAC.loadingSpinnerHide();
                            $('#manageUnEnroll').modal('hide');
                            $('#agreementUnEnroll').modal('hide');
                            $('#serviceDown').modal({
                                backdrop: 'dynamic',
                                keyboard: false
                            });
                            $('#serviceDown').modal('show');
                        },
                        complete: function () {
                            RAC.loadingSpinnerHide();
                            $('body').removeClass('modal-open');
                            $('.modal-backdrop').remove();
                            $('body').css("padding-right", "0px");
                            buttonElem.attr("disabled", false);
                        }
                    });
                }
                else {
                    window.location.href = "/";
                }
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                //$('#serviceErrorPopup').modal('show');
            })
            .always(function () {
            });

    });
    $(document).on('click', '#manageEnrollAuto', function() {
        handleSpinner();
        RAC.loadingSpinnerShow();
        getMyAccountPaymentOptions();
        var buttonElem = $(this);
        buttonElem.attr("disabled", true);
        sessionTimeoutCall()
            .done(function (data) {
                if (!checkLoginSessionTimeout(data)) {
                    $('#autoPayConfirmForm').submit();
                }
                else {
                    window.location.href = "/";
                }
            })
            .fail(function () {
                //$('#serviceErrorPopup').modal('show');
            })
            .always(function () {
            });
    });

    //enabling save and cancel button
    $(".column_6 select").on("change", function () {
        var countOn = 0;
        $('.selectType').each(function (i, obj) {
            if ($(this).val() != 0) {
                countOn++;
            }

        });
        if (countOn > 0) {
            $(".manageAutoPayProfilePane .save_button").removeAttr("disabled");
            //$(".manageAutoPayProfilePane .cancel_button").removeAttr("disabled");
        }
        else {
            $(".manageAutoPayProfilePane .save_button").attr("disabled", true);
            //$(".manageAutoPayProfilePane .cancel_button").attr("disabled",true);
        }
    });

    $(".mobileView .column_1 select").on("change", function () {
        var countOn1 = 0;
        $('.selectTypeMobile').each(function (i, obj) {
            if ($(this).val() != 0) {
                countOn1++;
            }

        });
        if (countOn1 > 0) {
            $(".manageAutoPayProfilePane .save_button").removeAttr("disabled");
            //$(".manageAutoPayProfilePane .cancel_button").removeAttr("disabled");
        }
        else {
            $(".manageAutoPayProfilePane .save_button").attr("disabled", true);
            //$(".manageAutoPayProfilePane .cancel_button").attr("disabled",true);
        }
    });




    $("#enrollConfirmation .close_button").on('click', function () {
        $("#enrollConfirmation").modal("hide");
        $('body').css("padding-right", "0px");
        location.reload();
    });
    $("#enrollConfirmation .close_btn").on('click', function () {
        $("#enrollConfirmation").modal("hide");
        $('body').css("padding-right", "0px");
        location.reload();
    });

    $('#enrollConfirmation').on('hidden.bs.modal', function (event) {
        $('body').css("padding-right", "0px");
    });

    $('#serviceDown').on('hidden.bs.modal', function (event) {
        $('body').css("padding-right", "0px");
    });

    $("#enrollModal .cancel_button").on('click', function () {
        $("#enrollModal").modal("hide");
    });
    $("#manageUnEnroll .cancel_button").on('click', function () {
        $("#manageUnEnroll").modal("hide");
    });
    $("#enrollConfirmation .close_btn").on("click", function () {
        loaction.reload();
    });
    $(".payment-details-info,.priceBlock .glyphicon-menu-down").hide();
    $('[data-toggle="tooltip"]').tooltip();
    $(".paymentDetails").on('click', function () {

        var paymentDetailInfo = $(this).parent().find(".payment-details-info");
        var currentRow = $(this).closest(".table-row").find(".row-data");
        var paymentMethod = $(this).parent().find(".paymentMethod");
        var priceValue = $(this).parent().find(".priceValue");
        var detailBlock = $(this).parent().find(".detailBlock");
        var priceBlock = $(this).parent().find(".priceBlock");
        var extendedBlock = $(this).parent().find(".extended_cloumn");
        if (paymentDetailInfo.is(':visible')) {
            paymentMethod.show();
            priceValue.show();
            paymentDetailInfo.hide();
            detailBlock.css("width", "24%");
            priceBlock.css("width", "20%");
            if ($(this).closest(".table-row").is(":last-child")) {
                currentRow.css("border-bottom", "1px solid #ccc");
            } else {
                currentRow.css("border-bottom", "none");
            }
            $(this).find('.sub-total-head').show();
            $(this).find('.glyphicon-menu-right').show();
            $(this).find('.glyphicon-menu-down').hide();
            extendedBlock.css('height', "50px")
        } else {
            paymentMethod.hide();
            priceValue.hide();
            paymentDetailInfo.show();
            detailBlock.css("width", "49%");
            priceBlock.css("width", "49%");
            currentRow.css("border-bottom", "1px solid #ccc");
            $(this).find('.sub-total-head').hide();
            $(this).find('.glyphicon-menu-right').hide();
            $(this).find('.glyphicon-menu-down').show();
            extendedBlock.css('height', "150px")
        }
    });

    //Handle timeout AJAX error
    $(document).ajaxError(function (event, jqxhr, settings, thrownError) {
        if (jqxhr.statusText === "timeout") {
            $(".modal").modal('hide');
            $('#serviceDown').modal({
                backdrop: 'dynamic',
                keyboard: false
            });
            $('#serviceDown').modal('show');
        }
    });
});
$('#paymentMethodEnroll').on('click', function () {
    window.location.href = ACC.config.encodedContextPath + "/myaccount/paymentmethods";

});

$('#reviewEnrollId').on('click', function () {
	var sAgreementIds = '';
    $('input.enrollCustomCheckboxEnabled:checked, input.enrollCustomCheckboxDisabled:checked').each(function () {
        if ($(this).attr('agreementId')) {
            agreementIds = ($(this).attr('agreementId'));
        }
        if(sAgreementIds=="") {
        	sAgreementIds = agreementIds;
        } else {
        	sAgreementIds = sAgreementIds + ',' + agreementIds;
        }
    });
    
    var selectedPaymentMethodId = $(".enrollselected-payment-value").attr("paymentmethodid");
    $('#selectedAgreementIds').val(sAgreementIds);
    $('#selectedPaymentMethodId').val(selectedPaymentMethodId);
    if (!sAgreementIds.length == 0) {
     $('#enrollPayContinueForm').submit();
    }
});

$('.processing-modal').on('shown.bs.modal', function () {
    $('.modal-backdrop').css('background-color', 'black');
});


$('#manageUnEnroll').on('shown.bs.modal', function () {
    $('.modal-backdrop').css('background-color', 'black');
});

//Collapse box if unchecked
$(document).ready(function() {
	$('.enrollDet input[type=checkbox]').each(function() {
		if(!this.checked) {
		    $(this).parents('.account-payment-card').find('.collapse-box').slideUp();
		    $(this).parents('.account-payment-card').removeClass('active');
		}
	});
});

//Update autopay form
$('body').on('click', '#updateAutopayDet', function(e) {
    handleSpinner();
    RAC.loadingSpinnerShow();
	$("#enrollAutopayUpdateForm").submit();
});